'use client';
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useListenForPWAAuth } from './useListenForPWAAuth';
import {
  AppUser,
  anonymouslySignIn,
  signIn,
  getAuthApp,
  parseUser,
  signOut,
  UserData,
  getUserDataStream,
  getPreAuthUserCachedData,
} from '@reshima/firebase';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { useManagedSyncStatus } from '@reshima/nav-bar-ui';
import { useMigrateAnonymousUserData } from './useMigrateAnonymousUserData';
import {
  deleteLastUserId,
  setLastUserId,
} from '@reshima/user-local-persistence';

export const testUserEmailKey = 'testUserEmail';

export type ClientAuthContext = {
  userLoading: boolean;
  user?: AppUser;
  userData?: UserData;
  testUserEmail: string;
  userDataLoading: boolean;
  isMigratingUserData: boolean;
  isSigningIn: boolean;
  anonymouslySignIn: typeof anonymouslySignIn;
  signIn: typeof signIn;
  signOut: typeof signOut;
};

export const clientAuthContext = createContext<ClientAuthContext>({
  userLoading: true,
  userDataLoading: true,
  isMigratingUserData: false,
  isSigningIn: false,
  testUserEmail: '',
  signIn: () => Promise.resolve(),
  anonymouslySignIn: () => Promise.resolve(null as unknown as AppUser),
  signOut: () => Promise.resolve(),
});

export function useClientAuth(): ClientAuthContext {
  return useContext(clientAuthContext);
}

export function ClientAuthProvider({
  children,
}: {
  children: React.ReactNode;
}): ReactElement {
  const name = 'ClientAuthProvider';
  const [userLoading, setUserLoading] = useState(true);
  const [isMigratingUserData, setIsMigratingUserData] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [testUserEmail, setTestUserEmail] = useState<string>('');
  const [user, setUser] = useState<AppUser>();
  const [userData, setUserData] = useState<UserData | undefined>(
    getPreAuthUserCachedData().userData,
  );
  const [userDataLoading, setUserDataLoading] = useState(true);

  const { setSyncGuest, setSyncByStreamSource } = useManagedSyncStatus();

  if (isMigratingUserData !== useMigrateAnonymousUserData({ fromUser: user })) {
    setIsMigratingUserData(!isMigratingUserData);
  }

  useListenForPWAAuth();

  const listenToOnAuthStateChanged = useCallback(() => {
    const action = 'OnAuthStateChanged';

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    return onAuthStateChanged(
      getAuthApp(),
      async (user) => {
        try {
          setUserLoading(false);

          if (!user) {
            setUser(undefined);

            deleteLastUserId();

            trackEvent({
              name,
              action,
              actionModifier: ActionModifier.End,
              start,
              properties: {
                user: !!user,
              },
            });

            return;
          }

          setLastUserId({ userId: user.uid });

          const parsedUser = parseUser(user);

          setUser(parsedUser);

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            start,
            properties: {
              user: !!user,
              providers: parsedUser.providers,
            },
          });
        } catch (error) {
          trackException({
            name,
            action,
            error,
            start,
          });
        }
      },
      (error) => {
        trackException({
          name,
          action,
          error,
        });
      },
    );
  }, []);

  const listenToUserDataStream = useCallback(
    (user?: AppUser) => {
      const action = 'GetUserDataStream';

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      if (!user) {
        setUserData(undefined);
        setSyncGuest();
        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.Skip,
          start,
        });
        return;
      }

      return getUserDataStream({
        userId: user.firebaseUser.uid,
        onUpdate: ({ user, source }) => {
          setUserData(user);
          setSyncByStreamSource(source);
          setUserDataLoading(false);

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            start,
          });
        },
        onError: (error) => {
          setUserDataLoading(false);
          trackException({
            name,
            action,
            start,
            error,
          });
        },
      });
    },
    [setSyncByStreamSource, setSyncGuest],
  );

  const _signIn = useCallback(async (params: Parameters<typeof signIn>[0]) => {
    setIsSigningIn(true);
    try {
      await signIn(params);
    } finally {
      setIsSigningIn(false);
    }
  }, []);

  useEffect(() => listenToOnAuthStateChanged(), [listenToOnAuthStateChanged]);
  useEffect(() => {
    if (userLoading) {
      return;
    }

    return listenToUserDataStream(user);
  }, [userLoading, user, listenToUserDataStream]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const _testUserEmail = searchParams.get(testUserEmailKey);
    if (_testUserEmail && _testUserEmail !== testUserEmail) {
      setTestUserEmail(_testUserEmail);
    }
  }, [testUserEmail]);

  return (
    <clientAuthContext.Provider
      value={{
        user,
        userLoading,
        userData,
        userDataLoading,
        testUserEmail,
        isMigratingUserData,
        isSigningIn,
        anonymouslySignIn,
        signIn: _signIn,
        signOut,
      }}
    >
      {children}
    </clientAuthContext.Provider>
  );
}
