import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { httpsCallable } from 'firebase/functions';
import { getCallable } from '../firebase-callable';
import { NewFirebaseList } from '../models';

const name = 'CreateList';

export async function createList(): Promise<NewFirebaseList> {
  const action = Action.Callable;

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const createListCallable = httpsCallable<void, NewFirebaseList>(
      getCallable(),
      'createList',
    );

    const { data } = await createListCallable();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties: {
        listId: data.id,
      },
    });

    return data;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
    throw error;
  }
}
