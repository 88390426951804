import { UserCredential } from 'firebase/auth';
import { Message, Targets, Types } from './messages';
import {
  BackgroundRecipientMessage,
  OffscreenRecipientMessage,
  PopupRecipientMessage,
  isBackgroundRecipientMessage,
  isOffscreenRecipientMessage,
  isPopupRecipientMessage,
} from './recipients';

type UserCredentialResponseMessage = Message & {
  type: Types.userCredentialResponse;
  userCredential: UserCredential;
};

export type OffscreenUserCredentialResponseMessage = OffscreenRecipientMessage &
  UserCredentialResponseMessage;

export type BackgroundUserCredentialResponseMessage =
  BackgroundRecipientMessage & UserCredentialResponseMessage;

type PopupUserCredentialResponseMessage = PopupRecipientMessage &
  UserCredentialResponseMessage;

function isUserCredentialResponseMessage(
  message: unknown,
): message is UserCredentialResponseMessage {
  return (
    (message as UserCredentialResponseMessage)?.type ===
      Types.userCredentialResponse &&
    !!(message as UserCredentialResponseMessage)?.userCredential
  );
}

export function isOffscreenUserCredentialResponseMessage(
  message: unknown,
): message is OffscreenUserCredentialResponseMessage {
  return (
    isOffscreenRecipientMessage(message) &&
    isUserCredentialResponseMessage(message)
  );
}

export function isBackgroundUserCredentialResponseMessage(
  message: unknown,
): message is BackgroundUserCredentialResponseMessage {
  return (
    isBackgroundRecipientMessage(message) &&
    isUserCredentialResponseMessage(message)
  );
}

export function isPopupUserCredentialResponseMessage(
  message: unknown,
): message is PopupUserCredentialResponseMessage {
  return (
    isPopupRecipientMessage(message) && isUserCredentialResponseMessage(message)
  );
}

export function createOffscreenUserCredentialResponseMessage({
  userCredential,
}: {
  userCredential: UserCredential;
}): OffscreenUserCredentialResponseMessage {
  return {
    target: Targets.offscreen,
    type: Types.userCredentialResponse,
    userCredential,
  };
}

export function createBackgroundUserCredentialResponseMessage({
  userCredential,
}: {
  userCredential: UserCredential;
}): BackgroundUserCredentialResponseMessage {
  return {
    target: Targets.background,
    type: Types.userCredentialResponse,
    userCredential,
  };
}

export function createPopupUserCredentialResponseMessage({
  userCredential,
}: {
  userCredential: UserCredential;
}): PopupUserCredentialResponseMessage {
  return {
    target: Targets.popup,
    type: Types.userCredentialResponse,
    userCredential,
  };
}
