import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { initAuth } from './firebase-auth';
import { initFirestore } from './firebase-firestore';
import { initCallable } from './firebase-callable';

const name = 'Firebase';

export async function initialize({
  config,
  analytics,
  lastUserId,
  lastVisitedList,
}: {
  config: FirebaseOptions;
  analytics: boolean;
  lastUserId?: string;
  lastVisitedList?: string;
}) {
  const action = 'Initialize';

  const properties = {
    analytics,
    lastUserId,
    lastVisitedList,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const app = initializeApp(config);

    if (analytics) {
      getAnalytics(app);
    }

    initFirestore(app);

    await initAuth({ app, lastUserId, lastVisitedList });

    initCallable(app);

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
  }
}
