import { PropsWithChildren, useState } from 'react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { AiOutlineLoading } from 'react-icons/ai';
import { LiaUserNinjaSolid, LiaUserSolid } from 'react-icons/lia';
import { useIsOnline } from '@reshima/shared-ui';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { useSyncStatus } from './context';
import { SyncStatus } from './models';

export function AccountMenuImage() {
  const {
    profileImageAltText,
    loadingUserAriaLabel,
    syncingAriaLabel,
    syncedAriaLabel,
    gustAriaLabel,
    anonymousAriaLabel,
    userAriaLabel,
  } = useTranslations()['account-menu'];
  const [userImageError, setUserImageError] = useState(false);
  const { user, userLoading, isSigningIn } = useClientAuth();
  const { isOnline } = useIsOnline();
  const { syncStatus } = useSyncStatus();

  const loadingUser = userLoading || isSigningIn;

  function getAriaLabel() {
    if (loadingUser) {
      return loadingUserAriaLabel;
    }

    if (user?.isAnonymous) {
      return anonymousAriaLabel;
    }

    if (user?.isSignedIn) {
      return userAriaLabel;
    }

    return gustAriaLabel;
  }

  function UserIcon() {
    if (loadingUser) {
      return (
        <div className="p-1.5">
          <AiOutlineLoading className="animate-spin" />
        </div>
      );
    }

    if (!user) {
      return (
        <div className="p-1.5">
          <LiaUserSolid />
        </div>
      );
    }

    if (user.isAnonymous) {
      return (
        <div className="p-1.5">
          <LiaUserNinjaSolid />
        </div>
      );
    }

    if (user.userImage && !userImageError) {
      return (
        <img
          src={user.userImage}
          onError={() => setUserImageError(true)}
          referrerPolicy="no-referrer"
          className="rounded-full w-9 h-9 p-0.5"
          alt={profileImageAltText}
        />
      );
    }

    return (
      <div className="p-1.5">
        <LiaUserSolid />
      </div>
    );
  }

  function SyncStatusIndicator({ children }: PropsWithChildren) {
    return (
      <div className="relative">
        {children}
        {isOnline && !loadingUser && (
          <>
            {syncStatus === SyncStatus.Syncing && (
              <div className="absolute inset-0" aria-label={syncingAriaLabel}>
                <div className="w-full h-full border-transparent border-s-base-content border-2 rounded-full animate-spin" />
              </div>
            )}
            {syncStatus === SyncStatus.Synced && (
              <div
                className="absolute bottom-0 bg-base-100 rounded-full"
                aria-label={syncedAriaLabel}
              >
                <IoCheckmarkCircleOutline className="text-sm text-primary-base" />
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className="text-2xl" aria-label={getAriaLabel()}>
      <SyncStatusIndicator>
        <UserIcon />
      </SyncStatusIndicator>
    </div>
  );
}
