import { Message, Targets } from './messages';

export type PopupRecipientMessage = Message & {
  target: Targets.popup;
};

export type BackgroundRecipientMessage = Message & {
  target: Targets.background;
};

export type OffscreenRecipientMessage = Message & {
  target: Targets.offscreen;
};

export type IframeRecipientMessage = Message & {
  target: Targets.iframe;
};

export function isPopupRecipientMessage(
  message: unknown,
): message is PopupRecipientMessage {
  return (message as PopupRecipientMessage)?.target === Targets.popup;
}

export function isBackgroundRecipientMessage(
  message: unknown,
): message is BackgroundRecipientMessage {
  return (message as BackgroundRecipientMessage)?.target === Targets.background;
}

export function isOffscreenRecipientMessage(
  message: unknown,
): message is OffscreenRecipientMessage {
  return (message as OffscreenRecipientMessage)?.target === Targets.offscreen;
}

export function isIframeRecipientMessage(
  message: unknown,
): message is IframeRecipientMessage {
  return (message as IframeRecipientMessage)?.target === Targets.iframe;
}
