import { Message, Targets, Types } from './messages';
import {
  IframeRecipientMessage,
  OffscreenRecipientMessage,
  isIframeRecipientMessage,
  isOffscreenRecipientMessage,
} from './recipients';

type ReadyRequestMessage = Message & {
  type: Types.readyRequest;
};

type OffscreenReadyRequestMessage = OffscreenRecipientMessage &
  ReadyRequestMessage;

export type IframeReadyRequestMessage = IframeRecipientMessage &
  ReadyRequestMessage;

function isReadyRequestMessage(
  message: unknown,
): message is ReadyRequestMessage {
  return (message as ReadyRequestMessage)?.type === Types.readyRequest;
}

export function isOffscreenReadyRequestMessage(
  message: unknown,
): message is OffscreenReadyRequestMessage {
  return isOffscreenRecipientMessage(message) && isReadyRequestMessage(message);
}

export function isIframeReadyRequestMessage(
  message: unknown,
): message is IframeReadyRequestMessage {
  return isIframeRecipientMessage(message) && isReadyRequestMessage(message);
}

export function createOffscreenReadyRequestMessage(): OffscreenReadyRequestMessage {
  return {
    target: Targets.offscreen,
    type: Types.readyRequest,
  };
}

export function createIframeReadyRequestMessage(): IframeReadyRequestMessage {
  return {
    target: Targets.iframe,
    type: Types.readyRequest,
  };
}
