'use client';

import classNames from 'classnames';
import { ButtonLink } from '@reshima/navigation-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';

const brand = 'רְשִׁימָה';

type Props = {
  className?: string;
};

export function Brand({ className }: Props) {
  const name = 'Brand';

  const { brandLinkAriaLabel } = useTranslations()['brand'];

  return (
    <ButtonLink
      className={classNames('text-xl font-semibold px-2 py-0.5', className)}
      href={`/`}
      ariaLabel={brandLinkAriaLabel}
      ghost
      tight
      onClick={() =>
        trackEvent({
          name,
          action: Action.Click,
          actionModifier: ActionModifier.End,
          properties: { brand },
        })
      }
    >
      {brand}
    </ButtonLink>
  );
}
