import { ReshimaPage } from '@reshima/shared-ui';
import { Dictionary } from '@reshima/translations';
import { SignIn } from '@reshima/client-auth-ui';

export function SignInPage({ dictionary }: { dictionary: Dictionary }) {
  const { heading, description } = dictionary['sign-in'];

  return (
    <ReshimaPage name="SignInPage" heading={heading} description={description}>
      <SignIn />
    </ReshimaPage>
  );
}
