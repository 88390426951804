import {
  CollectionReference,
  DocumentData,
  FirestoreError,
  QueryDocumentSnapshot,
  Unsubscribe,
  collection,
  getDocs,
  onSnapshot,
} from 'firebase/firestore';
import { FirebaseParticipant, Participant, StreamSource } from '../models';
import { getFirestoreApp } from '../firebase-firestore';
import { getStreamSource } from './metadata';

export const getListParticipantsCollection = ({ listId }: { listId: string }) =>
  collection(
    getFirestoreApp(),
    `lists/${listId}/participants`,
  ) as CollectionReference<FirebaseParticipant>;

function parse({
  id,
  index,
  participant,
}: {
  id: string;
  index: number;
  participant?: FirebaseParticipant;
}): Participant {
  const createdAt = participant?.createdAt?.toDate() || new Date();
  const name = participant?.name || '';

  return {
    ...participant,
    id,
    index,
    name,
    createdAt,
  };
}

function sortParticipants(
  a: QueryDocumentSnapshot<FirebaseParticipant, DocumentData>,
  b: QueryDocumentSnapshot<FirebaseParticipant, DocumentData>,
): number {
  const createdAtA = a.data().createdAt?.toDate().getTime() || 0;
  const createdAtB = b.data().createdAt?.toDate().getTime() || 0;
  return createdAtA - createdAtB;
}

export async function getParticipants({
  listId,
}: {
  listId: string;
}): Promise<Participant[]> {
  const participants = await getDocs(getListParticipantsCollection({ listId }));

  return participants.docs.sort(sortParticipants).map((doc, index) =>
    parse({
      id: doc.id,
      index,
      participant: doc.data(),
    }),
  );
}

export function getParticipantsStream({
  listId,
  onUpdate,
  onError,
}: {
  listId: string;
  onUpdate: (params: {
    participants: Participant[];
    source: StreamSource;
  }) => void;
  onError: (error: FirestoreError) => void;
}): Unsubscribe {
  return onSnapshot(
    getListParticipantsCollection({ listId }),
    { includeMetadataChanges: true },
    (snapshot) => {
      const participants = snapshot.docs
        .sort(sortParticipants)
        .map((doc, index) =>
          parse({
            id: doc.id,
            index,
            participant: doc.data(),
          }),
        );

      const source = getStreamSource(snapshot);

      onUpdate({ participants, source });
    },
    onError,
  );
}
