'use client';
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { SyncStatus } from './models';
import { randomHash } from '@reshima/shared';
import { StreamSource } from '@reshima/firebase';

type SyncStatusesMap = Record<string, SyncStatus>;

type SyncEntry = {
  id: string;
  updateStatus: (status: SyncStatus) => void;
  cleanup: () => void;
};

interface SyncContext {
  syncStatus: SyncStatus;
  registerSyncStatus: () => SyncEntry;
}

const syncStatusContext = createContext<SyncContext>({
  syncStatus: SyncStatus.Synced,
  registerSyncStatus: () => ({
    id: '',
    updateStatus: () => undefined,
    cleanup: () => undefined,
  }),
});

export const SyncStatusProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [syncStatusesMap, setSyncStatusesMap] = useState<SyncStatusesMap>({});

  const registerSyncStatus = useCallback(() => {
    const id = randomHash(5);
    setSyncStatusesMap((prev) => ({
      ...prev,
      [id]: SyncStatus.Syncing,
    }));

    const updateStatus = (status: SyncStatus) => {
      setSyncStatusesMap((prev) => ({
        ...prev,
        [id]: status,
      }));
    };

    const cleanup = () => {
      setSyncStatusesMap((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    };

    return { id, updateStatus, cleanup };
  }, []);

  const syncStatus = useMemo(() => {
    const statuses = Object.values(syncStatusesMap);

    if (
      !statuses.length ||
      statuses.every((status) => status === SyncStatus.Gust)
    ) {
      return SyncStatus.Gust;
    }

    return statuses.includes(SyncStatus.Syncing)
      ? SyncStatus.Syncing
      : SyncStatus.Synced;
  }, [syncStatusesMap]);

  return (
    <syncStatusContext.Provider
      value={{
        syncStatus,
        registerSyncStatus,
      }}
    >
      {children}
    </syncStatusContext.Provider>
  );
};

export const useSyncStatus = () => {
  return useContext(syncStatusContext);
};

export const useManagedSyncStatus = () => {
  const { registerSyncStatus } = useSyncStatus();
  const [syncEntry, setSyncEntry] = useState<SyncEntry>();

  useEffect(() => {
    const entry = registerSyncStatus();
    setSyncEntry(entry);
    return () => entry.cleanup();
  }, [registerSyncStatus]);

  const setSync = useCallback(
    (status: SyncStatus) => syncEntry?.updateStatus(status),
    [syncEntry],
  );

  const setSyncSyncing = useCallback(
    () => syncEntry?.updateStatus(SyncStatus.Syncing),
    [syncEntry],
  );

  const setSyncSynced = useCallback(
    () => syncEntry?.updateStatus(SyncStatus.Synced),
    [syncEntry],
  );

  const setSyncGuest = useCallback(
    () => syncEntry?.updateStatus(SyncStatus.Gust),
    [syncEntry],
  );

  const setSyncError = useCallback(
    () => syncEntry?.updateStatus(SyncStatus.Error),
    [syncEntry],
  );

  const setSyncByStreamSource = useCallback(
    (source: StreamSource) =>
      source === StreamSource.Client ? setSyncSyncing() : setSyncSynced(),
    [setSyncSynced, setSyncSyncing],
  );

  return {
    setSync,
    setSyncByStreamSource,
    setSyncSyncing,
    setSyncSynced,
    setSyncGuest,
    setSyncError,
  };
};
