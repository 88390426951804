import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { httpsCallable } from 'firebase/functions';
import { getCallable } from '../firebase-callable';
import { MigrateListsRequestData, MigrateListsResponseData } from '../models';

const name = 'MigrateLists';

export async function migrateLists({
  toUserId,
}: {
  toUserId: string;
}): Promise<void> {
  const action = Action.Callable;

  const properties = {
    toUserId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const migrateListsCallable = httpsCallable<
      MigrateListsRequestData,
      MigrateListsResponseData
    >(getCallable(), 'migrateLists');

    await migrateListsCallable({ toUserId });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}
