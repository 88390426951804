declare global {
  interface Window {
    postMessagePort: MessagePort;
  }
}

export function isIosAuth() {
  return (
    // @ts-expect-error - iOS PWA specific code
    !!window?.webkit?.messageHandlers?.googleSignIn &&
    // @ts-expect-error - iOS PWA specific code
    !!window?.webkit?.messageHandlers?.appleSignIn
  );
}

export function isAndroidAuth() {
  return !!window.postMessagePort;
}

export function isPWAAuth() {
  return isIosAuth() || isAndroidAuth();
}
