'use client';
import { useCallback, useEffect } from 'react';
import { ProviderId } from '@reshima/shared';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import {
  signInWithCredential,
  isIosAuth,
  isAndroidAuth,
} from '@reshima/firebase';

type TokenMessage = {
  idToken: string;
  accessToken: string;
  email: string;
  providerId: ProviderId;
};

type ErrorMessage = {
  error: string;
};

type Message = TokenMessage | ErrorMessage;

const invalidMessage = 'Invalid message received';

function isTokenMessage(message: Message): message is TokenMessage {
  return (message as TokenMessage).idToken !== undefined;
}

export function useListenForPWAAuth() {
  const name = 'UseListenForPWAAuth';

  const listenForIOSAuth = useCallback(() => {
    const action = 'ListenForIOSAuth';
    const event = 'pwa-auth';

    if (!isIosAuth()) {
      return;
    }

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    function handleIOSMessage(event: CustomEvent) {
      const message: Message = event.detail;

      if (isTokenMessage(message)) {
        signInWithCredential(event.detail);
        return;
      }

      const error = new Error(message.error || invalidMessage);

      trackException({
        name,
        action,
        error,
      });
    }

    window.addEventListener(event, handleIOSMessage as EventListener);

    return () =>
      window.removeEventListener(event, handleIOSMessage as EventListener);
  }, []);

  useEffect(listenForIOSAuth, [listenForIOSAuth]);

  const listenForAndroidAuth = useCallback(() => {
    const action = 'ListenForAndroidAuth';

    if (!isAndroidAuth()) {
      return;
    }

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    window.postMessagePort.onmessage = (event) => {
      try {
        const messgae: Message = JSON.parse(event.data);

        if (isTokenMessage(messgae)) {
          signInWithCredential(messgae);
          return;
        }

        throw new Error(messgae.error || invalidMessage);
      } catch (error) {
        trackException({
          name,
          action,
          error,
        });
      }
    };
  }, []);

  useEffect(listenForAndroidAuth, [listenForAndroidAuth]);
}
