import { ThemeToggler } from '@reshima/theme';
import AccountMenu from './account-menu';
import { Brand } from './brand';

export function NavBar() {
  return (
    <>
      <div className="fixed z-10 top-0 w-full h-10 bg-base-200 border-b border-base-300"></div>
      <nav className="sticky z-20 h-10 px-1 xs:px-2">
        <div className="w-full max-w-sm mx-auto h-full flex items-center justify-between">
          <ThemeToggler />
          <Brand />
          <AccountMenu />
        </div>
      </nav>
    </>
  );
}
