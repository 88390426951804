import { useCallback, useEffect, useState } from 'react';
import { beforeAuthStateChanged, Unsubscribe } from 'firebase/auth';
import { AppUser, getAuthApp, migrateLists } from '@reshima/firebase';
import { trackEvent, ActionModifier, trackException } from '@reshima/telemetry';

export function useMigrateAnonymousUserData({
  fromUser,
}: {
  fromUser?: AppUser;
}): boolean {
  const name = 'MigrateAnonymousUserData';
  const [isMigratingUserData, setIsMigratingUserData] = useState(false);

  const setBeforeAuthStateChanged: (params: {
    fromUser: AppUser;
  }) => Unsubscribe = useCallback(({ fromUser }) => {
    const action = 'BeforeAuthStateChanged';

    return beforeAuthStateChanged(getAuthApp(), async (toUser) => {
      const properties = {
        fromUser: fromUser.firebaseUser.uid,
        toUser: toUser?.uid,
      };

      if (!toUser?.uid) {
        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.Skip,
          properties,
        });

        return;
      }

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      setIsMigratingUserData(true);

      try {
        await migrateLists({ toUserId: toUser.uid });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          start,
          properties,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          start,
          properties,
        });
        throw error;
      } finally {
        setIsMigratingUserData(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!fromUser) return;
    return setBeforeAuthStateChanged({ fromUser });
  }, [fromUser, setBeforeAuthStateChanged]);

  return isMigratingUserData;
}
