import {
  List,
  Item,
  getListFromCache,
  getItemsFromCache,
  UserData,
  getUserDataFromCache,
} from '@reshima/firebase';
import { timeoutPromise } from '@reshima/shared';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';

type UserCachedData = {
  userData?: UserData;
};

type ListCachedData = {
  list?: List;
  items?: Item[];
};

const name = 'PreAuthCachedData';

let userCachedData: UserCachedData = {};
let listCachedData: ListCachedData = {};

async function initUserData({
  lastUserId,
}: {
  lastUserId?: string;
}): Promise<void> {
  const action = 'InitUserData';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const properties = { lastUserId };

    if (!lastUserId) {
      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Skip,
        start,
        properties,
      });
      return;
    }

    const userData = await timeoutPromise(
      getUserDataFromCache({ userId: lastUserId }),
      1000,
    );

    if (!userData) {
      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Skip,
        start,
        properties,
      });
      return;
    }

    userCachedData = {
      userData,
    };

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties: {
        ...properties,
        listId: userData.id,
      },
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
  }
}

export async function initListData({
  lastVisitedList,
}: {
  lastVisitedList?: string;
}): Promise<void> {
  const action = 'InitListData';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const properties = { lastVisitedList };

    if (!lastVisitedList) {
      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Skip,
        start,
        properties,
      });
      return;
    }

    const [list, items] = await Promise.all([
      timeoutPromise(
        getListFromCache({ listId: lastVisitedList, defaultListName: '' }),
        1000,
      ),
      timeoutPromise(getItemsFromCache({ listId: lastVisitedList }), 1000),
    ]);

    if (!list || !items) {
      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Skip,
        start,
        properties: {
          ...properties,
          list: !!list,
          items: !!items,
        },
      });
      return;
    }

    listCachedData = {
      list,
      items,
    };

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties: {
        ...properties,
        listId: list.id,
        itemsCount: items.length,
      },
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
  }
}

export async function initPreAuthCachedData({
  lastUserId,
  lastVisitedList,
}: {
  lastUserId?: string;
  lastVisitedList?: string;
}): Promise<void> {
  await Promise.allSettled([
    initUserData({ lastUserId }),
    initListData({ lastVisitedList }),
  ]);
}

export function getPreAuthUserCachedData(): UserCachedData {
  const data = userCachedData;

  userCachedData = {};

  return data;
}

export function getPreAuthListCachedData(): ListCachedData {
  const data = listCachedData;

  listCachedData = {};

  return data;
}
