import { getCountFromServer, query, where } from 'firebase/firestore';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { Item } from '../models';
import { isItemChecked, isItemUnchecked } from '../utils';
import { getItemsFromCache, getListItemsCollection } from './items';

async function getItemsCountFromServer({
  listId,
  checked,
}: {
  listId: string;
  checked: boolean;
}): Promise<number> {
  const name = 'getItemsCountFromServer';

  const action = Action.Count;

  const properties = { listId, checked };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  const checkedField: keyof Item = 'checked';

  try {
    const snapshot = await getCountFromServer(
      query(
        getListItemsCollection({ listId }),
        where(checkedField, '==', checked),
      ),
    );

    const { count } = snapshot.data();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: { ...properties, count },
      start,
    });

    return count;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

async function getItemsCountFromCache({
  listId,
  checked,
}: {
  listId: string;
  checked: boolean;
}): Promise<number> {
  const name = 'getItemsCountFromCache';

  const action = Action.Count;

  const properties = { listId, checked };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const items = await getItemsFromCache({ listId });

    const count = items.filter(
      checked ? isItemChecked : isItemUnchecked,
    ).length;

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: { ...properties, count },
      start,
    });
    return count;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function getUncheckedItemsCount({
  listId,
}: {
  listId: string;
}): Promise<number> {
  const checked = false;
  try {
    return await getItemsCountFromServer({ listId, checked });
  } catch {
    return await getItemsCountFromCache({ listId, checked });
  }
}

export async function getCheckedItemsCount({
  listId,
}: {
  listId: string;
}): Promise<number> {
  const checked = true;
  try {
    return await getItemsCountFromCache({ listId, checked });
  } catch {
    return await getItemsCountFromServer({ listId, checked });
  }
}
