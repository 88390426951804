import { getBackendURL, backendRoutes } from '@reshima/client-server';
import { CategoriesBatchPayload, SearchResults } from './models';

const { category, categoryAutoComplete, gcpCategory } = backendRoutes;

export async function getCategory({
  search,
  token,
}: {
  search: string;
  token: string;
}): Promise<SearchResults> {
  const { path, query } = category;

  const baseUrl = `${getBackendURL()}${path}`;

  const params = new URLSearchParams({
    [query.search]: search,
  });

  const url = `${baseUrl}?${params}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}

export async function suggestCategory({
  search,
  token,
}: {
  search: string;
  token: string;
}): Promise<SearchResults> {
  const { path, query } = categoryAutoComplete;

  const baseUrl = `${getBackendURL()}${path}`;

  const params = new URLSearchParams({
    [query.search]: search,
  });

  const url = `${baseUrl}?${params}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}

export async function requestGcpCategory({
  search,
  token,
  userId,
}: {
  search: string;
  token: string;
  userId: string;
}): Promise<SearchResults> {
  const { path, query } = gcpCategory;

  const baseUrl = `${getBackendURL()}${path}`;

  const params = new URLSearchParams({
    [query.search]: search,
    [query.userId]: userId,
  });

  const url = `${baseUrl}?${params}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}

export async function batchGetCategories({
  token,
  searches,
}: {
  token: string;
  searches: string[];
}): Promise<Record<string, SearchResults>> {
  const { path } = backendRoutes.categoriesBatch;

  const baseUrl = `${getBackendURL()}${path}`;

  const payload: CategoriesBatchPayload = { searches };

  const response = await fetch(baseUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}
