import { firebaseRegion } from './constants';
import {
  SignInTestUserRequestData,
  SignInTestUserResponseData,
} from './models';

type FunctionName = 'signInTestUser';

function getHttpFunctionUrl(functionName: FunctionName): string {
  return `https://${firebaseRegion}-${process.env['NEXT_PUBLIC_FIREBASE_PROJECT_ID']}.cloudfunctions.net/${functionName}`;
}

export async function postSignInTestUser(
  body: SignInTestUserRequestData,
): Promise<SignInTestUserResponseData> {
  const url = getHttpFunctionUrl('signInTestUser');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(`Failed to sign in test user: ${response.statusText}`);
  }

  return response.json();
}
