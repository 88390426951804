import { DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';
import { StreamSource } from '../models';

export function getStreamSource({
  metadata: { fromCache, hasPendingWrites },
}: DocumentSnapshot | QuerySnapshot): StreamSource {
  return fromCache || hasPendingWrites
    ? StreamSource.Client
    : StreamSource.Server;
}
