export const statedCategoriesData = {
  Loading: {
    name: 'טוען',
    icon: '🔄',
  },
} as const;

export const categoriesData = {
  Other: {
    name: 'אחר',
    icon: '❔',
  },
  Spices: {
    name: 'תבלינים',
    icon: '🧂',
  },
  CookingAndBaking: {
    name: 'בישול ואפייה',
    icon: '👨‍🍳',
  },
  Canning: {
    name: 'שימורים',
    icon: '🥫',
  },
  Babies: {
    name: 'תינוקות',
    icon: '👶',
  },
  BabyFood: {
    name: 'מזון לתינוקות',
    icon: '🍼',
  },
  Animals: {
    name: 'בעלי חיים',
    icon: '🐾',
  },
  Fish: {
    name: 'דגים',
    icon: '🐟',
  },
  MeatPoultry: {
    name: 'בשר ועוף',
    icon: '🍖',
  },
  VitaminsAndSupplements: {
    name: 'ויטמינים ותוספי תזונה',
    icon: '💊',
  },
  SnacksAndSweets: {
    name: 'חטיפים ומתוקים',
    icon: '🍬',
  },
  CerealsAndGrainSnacks: {
    name: 'דגנים וחטיפי דגנים',
    icon: '🥣',
  },
  BreadsAndBakery: {
    name: 'לחמים ומוצרי מאפה',
    icon: '🍞',
  },
  DairyAndEggs: {
    name: 'מוצרי חלב וביצים',
    icon: '🧀',
  },
  ChilledAndFrozenFood: {
    name: 'מזון מקורר וקפוא',
    icon: '❄️',
  },
  Beverages: {
    name: 'שתייה ומשקאות',
    icon: '🍷',
  },
  TeaAndCoffee: {
    name: 'תה וקפה',
    icon: '☕',
  },
  Insecticides: {
    name: 'קוטלי חרקים',
    icon: '🐜',
  },
  Cleaning: {
    name: 'ניקיון',
    icon: '🧼',
  },
  PapersAndWipes: {
    name: 'נייר ומגבונים',
    icon: '🧻',
  },
  Disposable: {
    name: 'חד פעמי',
    icon: '🍽️',
  },
  CigarettesAndSmoking: {
    name: 'סיגריות ועישון',
    icon: '🚬',
  },
  Hair: {
    name: 'טיפוח שיער',
    icon: '🪮',
  },
  Nails: {
    name: 'טיפוח ציפורניים',
    icon: '💅',
  },
  Makeup: {
    name: 'איפור',
    icon: '💄',
  },
  Optics: {
    name: 'אופטיקה',
    icon: '👓',
  },
  OralHygiene: {
    name: 'היגיינת הפה',
    icon: '🪥',
  },
  Shaving: {
    name: 'גילוח',
    icon: '🪒',
  },
  Pharmacy: {
    name: 'בית מרקחת',
    icon: '⚕️',
  },
  PharmacyAndBaby: {
    name: 'פארם ותינוקות',
    icon: '🧴',
  },
  Vegetables: {
    name: 'ירקות',
    icon: '🥕',
  },
  Fruits: {
    name: 'פירות',
    icon: '🍎',
  },
  NutsAndDriedFruits: {
    name: 'פיצוחים ופירות יבשים',
    icon: '🥜',
  },
  SaucesAndSpreads: {
    name: 'רטבים וממרחים',
    icon: '🍯',
  },
  OrganicAndHealthy: {
    name: 'אורגני ובריאות',
    icon: '🌱',
  },
  ElectronicsAndPhones: {
    name: 'אלקטרוניקה וסלולר',
    icon: '📱',
  },
  Batteries: {
    name: 'סוללות',
    icon: '🔋',
  },
  Appliances: {
    name: 'מוצרי חשמל',
    icon: '🔌',
  },
  ToysAndGames: {
    name: 'צעצועים ומשחקים',
    icon: '🎲',
  },
  BagsAndLuggages: {
    name: 'תיקים ומזוודות',
    icon: '💼',
  },
  WritingSupplies: {
    name: 'כלי כתיבה',
    icon: '✏️',
  },
  KitchenAndHosting: {
    name: 'מטבח ואירוח',
    icon: '🍽️',
  },
  Textile: {
    name: 'טקסטיל',
    icon: '🧵',
  },
  Furniture: {
    name: 'ריהוט',
    icon: '🪑',
  },
  CampingAndTraveling: {
    name: 'טיולים וקמפינג',
    icon: '🏕️',
  },
  Sport: {
    name: 'ספורט',
    icon: '🏋️',
  },
  GrillAndBarbecue: {
    name: 'גריל וברביקיו',
    icon: '♨️',
  },
  BedsAndMattresses: {
    name: 'מיטות ומזרנים',
    icon: '🛏️',
  },
  Laundry: {
    name: 'כביסה',
    icon: '🧺',
  },
  Bathroom: {
    name: 'אמבטיה',
    icon: '🛁',
  },
  Toilet: {
    name: 'שירותים',
    icon: '🚽',
  },
  Garden: {
    name: 'גינון',
    icon: '🌻',
  },
  WorkTools: {
    name: 'כלי עבודה',
    icon: '🛠️',
  },
  Vehicle: {
    name: 'רכב',
    icon: '🚗',
  },
  Garments: {
    name: 'פריטי לבוש',
    icon: '👕',
  },
  Footwear: {
    name: 'הנעלה',
    icon: '👟',
  },
  HeadCoverings: {
    name: 'כיסויי ראש',
    icon: '🧢',
  },
} as const;

export const allCategoriesData = {
  ...statedCategoriesData,
  ...categoriesData,
} as const;
